import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { LayoutBildRegistration, LayoutMissionMobile } from "@components";
import InvestmentTest from "@components/Missions/SavingAndInvestment/InvestmentTest/InvestmentTest";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { useAuth } from "@hooks";

const Descubrir = () => {
  useAuth({ redirectIfNotLogged: true });

  const isMobile = useMediaQuery("(max-width: 1280px)");
  const [isStep, setStep] = useState(-1);

  return isMobile ? (
    <LayoutMissionMobile
      title={"Test de Perfil de Inversión"}
      number={2}
      onHouseClick={() => navigate(Route.savingsAndInvestment)}
    >
      <InvestmentTest isStep={isStep} setStep={setStep} />
    </LayoutMissionMobile>
  ) : (
    <LayoutBildRegistration
      title="Test de Perfil de Inversión"
      subtitle={
        isStep === -1
          ? "Cuéntanos un poco de ti"
          : isStep === 0
          ? "Sólo un par de preguntas más"
          : "Tu resultado"
      }
      number={2}
    >
      <InvestmentTest isStep={isStep} setStep={setStep} />
    </LayoutBildRegistration>
  );
};
export default Descubrir;
